import React from "react";

// import MapBox from "../mapBox/MapBox";
import LoadingSpinner from "../UI/LoadingSpinner";
import classes from "./Contact.module.css";

const MapBox = React.lazy(() => import("../mapBox/MapBox"));

const Contact = () => {
  return (
    <div className="wrapper">
      <section className="section section__big">
        <h3 className="heading">KONTAKT</h3>
        <p>
          V ordinaci nemám recepci, která by vyřizovala objednávky. Pokud
          pracuji, nemůžu od klienta s odcházet a zvedat telefon. Prosím v
          případě, že se nedovoláte zanechte mi sms, ozvu se Vám zpět hned,
          jakmile to bude možné.
        </p>
      </section>
      <section className="section section__big" id={classes.contactSection}>
        <div>
          <div className={classes.contactBox}>
            <p className={classes.contactLabel}>Tereza Dobrová DiS.</p>
            <a
              className={classes.contactInfo}
              href="https://www.google.com/maps/place/T%C4%9B%C5%A1nov+1163%2F5,+110+00+Nov%C3%A9+M%C4%9Bsto/@50.0911374,14.4341547,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94be4bf82f55:0x183363d5ba358590!8m2!3d50.091134!4d14.4363434"
              target="_blank"
              rel="noreferrer"
            >
              Těšnov 1163/5, Praha 1, 110 00, 4. patro
            </a>
          </div>
          <div className={classes.contactBox}>
            <p className={classes.contactLabel}>Email</p>
            <a
              className={classes.contactInfo}
              href="mailto:terezadobrova@email.cz"
            >
              terezadobrova@email.cz
            </a>
          </div>
          <div className={classes.contactBox}>
            <p className={classes.contactLabel}>Telefon</p>
            <a className={classes.contactInfo} href="tel:+420736711678">
              +420 736 711 678
            </a>
          </div>
          <div className={classes.contactBox}>
            <p className={classes.contactLabel}>Instagram</p>
            <a
              className={classes.contactInfo}
              href="https://www.instagram.com/bojovnice.za.krasny.usmev/"
              target="_blank"
              rel="noreferrer"
            >
              bojovnice.za.krasny.usmev
            </a>
          </div>
          <div className={classes.contactBox}>
            <p className={classes.contactLabel}>Online objednání</p>
            <a
              className={classes.contactInfo}
              href="https://bojovnicezakrasnyusmev.xdent.cz/clinicSelection"
              target="_blank"
              rel="noreferrer"
            >
              bojovnicezakrasnyusmev.xdent.cz
            </a>
          </div>
        </div>
        <div className={classes.mapWrap}>
          <React.Suspense
            fallback={<LoadingSpinner size="10rem" margin="20rem" />}
          >
            <MapBox address={"Těšnov 1163/5, Praha 1, 110 00, 4. patro"} />
          </React.Suspense>
        </div>
      </section>
    </div>
  );
};

export default Contact;
