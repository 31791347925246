import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../assets/logo_full.png";

import classes from "./Navigation.module.css";

const Navigation = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const navClasses = !showNav
    ? classes.nav
    : classes.nav + " " + classes["show-nav"];

  const hamburgerClasses = !showNav
    ? classes.hamburger
    : classes.hamburger + " " + classes.cross;

  const backdropClasses = !showNav
    ? classes.backdrop
    : classes.backdrop + " " + classes["show-nav"];

  return (
    <div className={classes.navbar}>
      <div className={classes.wrap}>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>

        <div className={hamburgerClasses} onClick={toggleNav}>
          <div className={classes["hamburger__line"]} />
        </div>
      </div>
      <div className={backdropClasses} onClick={toggleNav} />
      <nav className={navClasses}>
        <ul className={classes.list}>
          <li>
            <NavLink to="/" exact onClick={toggleNav}>
              ÚVOD
            </NavLink>
          </li>
          <li>
            <NavLink to="/novinky" onClick={toggleNav}>
              NOVINKY
            </NavLink>
          </li>
          <li>
            <NavLink to="/osetreni-podle-gbt-protokolu" onClick={toggleNav}>
              OŠETŘENÍ PODLE GBT PROTOKOLU
            </NavLink>
          </li>
          <li>
            <NavLink to="/fotogalerie" onClick={toggleNav}>
              FOTOGALERIE
            </NavLink>
          </li>
          <li>
            <NavLink to="/cenik" onClick={toggleNav}>
              CENÍK
            </NavLink>
          </li>
          <li>
            <NavLink to="/ordinacni-doba" onClick={toggleNav}>
              ORDINAČNÍ DOBA
            </NavLink>
          </li>
          <li>
            <NavLink to="/kontakt" onClick={toggleNav}>
              KONTAKT
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
