import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const Gallery = ({ imgs }) => {
  const images = imgs.map((img) => ({
    src: img.src,
    alt: img.alt,
  }));

  return (
    <Carousel
      hasThumbnails={false}
      images={images}
      style={{ height: "30vh", minHeight: "30rem", width: "100%" }}
      isAutoPlaying={true}
      hasMediaButton={false}
      hasIndexBoard={false}
      hasDotButtons="bottom"
      shouldMaximizeOnClick={true}
    />
  );
};

export default Gallery;
