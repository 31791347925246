import img7 from "../../assets/edited-7.jpg";
import img15 from "../../assets/edited-15.jpg";
import img17 from "../../assets/edited-17.jpg";
import imgGbt from "../../assets/gbt.jpg";
import imgSD from "../../assets/sd.jpg";
import Gallery from "../UI/Gallery";

const GBT = () => {
  const images = [
    {
      src: img7,
      alt: "Nástroje",
    },
    {
      src: img15,
      alt: "Tereza Dobrová DiS.",
    },
    {
      src: img17,
      alt: "Nástroje",
    },
  ];

  return (
    <div className="wrapper">
      <div className="section section__big">
        <h3 className="heading">OŠETŘENÍ PODLE GBT PROTOKOLU</h3>
        <article className="article">
          <h4 className="artHeading">CO JE GBT?</h4>
          <p>
            V rámci spolupráce s univerzitami a zubními lékaři vyvinula
            švýcarská společnost EMS klinický protokol určený k odstranění
            biofilmu a udržení zdravých zubů během života. Tento protokol, zvaný
            GUIDED BIOFILM THERAPY, kombinuje profesionální čištění zubů v
            ordinaci a řádnou domácí péči o zuby. Neexistuje lepší způsob
            udržení škodlivého biofilmu pod kontrolou.
          </p>
          <br />
          <br />
          <br />

          <Gallery imgs={images} />
          <br />
          <br />
          <br />
          <h4 className="artHeading">BEZBOLESTNÉ</h4>
          <p>
            Díky profesionálnímu čištění zubů s GUIDED BIOFILM THERAPY se cítíte
            dobře. Čištění je jako wellness zážitek. Ošetření je bezbolestné a
            nepoškozuje zuby. GBT mění pravidla hry a nemá nic společného se
            starými metodami dentální hygieny z 60. let. S EMS přístroji
            AIRFLOW® a PIEZON® PS No Pain je voda, vzduch a prášek AIRFLOW® PLUS
            vše, co potřebujete k boji proti zákeřnému biofilmu s vítězným
            úsměvem na rtech. <br />
            Dozvíte se zde, jak chránit zuby před škodlivým biofilmem po celý
            život a jak sdílet tento jedinečný pocit čistých zubů se světem
            kolem vás.
          </p>
          <br />
          <br />
          <br />
          <img className="sectionImage" src={imgGbt} alt="8 kroků gbt" />
        </article>

        <h3 className="heading">ČISTÉ ZUBY, ZDRAVÉ TĚLO</h3>
        <article className="article">
          <h4 className="artHeading">BIOFILM ZPŮSOBUJE:</h4>
          <p>
            Zubní kaz, zápach z úst, ztrátu zubů, ztrátu implantátů
            <br />
            Gingivitidu = zánět dásní
            <br /> Parodontální onemocnění = infekce dásní, ustupující dásně
          </p>
          <br />
          <br />
          <h4 className="artHeading">SYSTÉMOVÉ ONEMOCNĚNÍ:</h4>
          <p>
            Studie ukazují, že biofilm zvyšuje riziko Alzheimerovy choroby,
            srdečních chorob, poruch dýchacích cest, cukrovky a dalších.
          </p>
          <br />
          <br />
          <img
            className="sectionImage"
            src={imgSD}
            alt="Systémová onemocnění"
          />
        </article>
        <p>
          Zdroj:{" "}
          <a href="https://www.switchtogbt.com/cs">www.switchtogbt.com</a>
        </p>
      </div>
    </div>
  );
};

export default GBT;
