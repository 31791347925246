import { config } from "../../config/config";
import ReactHtmlParser from "react-html-parser";

const News = () => {
  return (
    <div className="wrapper">
      <div className="section section__big">
        <h3 className="heading">NOVINKY</h3>
        <div className="articles">
          {config.map((post) => {
            return (
              <article className="article" key={post.id}>
                <h4 className="artHeading">{post.title}</h4>
                <p className="date">{post.date}</p>
                {ReactHtmlParser(post.content)}
              </article>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default News;
