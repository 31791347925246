export const config = [
  {
    id: 7,
    title: "Platba pouze hotově nebo přes QR kód",
    date: "23. 1. 2024",
    content:
      "<p>Od 1. 2. 2024 přijímám platby pouze hotově nebo přes QR kód.</p>",
  },
  {
    id: 7,
    title: "Triky, jak dokonale vyčistit mezizubní prostory.",
    date: "30. 12. 2022",
    content:
      "<p>Rozhovor pro <a href='https://www.notino.cz/beautyblog/' target='_blank'>Notino Beauty Blog</a> si můžete přečíst <a href='https://www.notino.cz/beautyblog/tipy-a-rady/triky-jak-dokonale-vycistit-mezizubni-prostory/' target='_blank'>zde</a>.</p>",
  },
  {
    id: 6,
    title: "Nový ceník výkonů pro rok 2023",
    date: "30. 12. 2022",
    content:
      "<p>Od 1.1. 2023 došlo ke změně ceníku. Cenové předpisy („kalkulace“) jsou povinné pro všechna zdravotnická zařízení (dle zákona 526/1990 Sb., vyhlášky 450/2009 a dalších).</p>",
  },
  {
    id: 5,
    title: "Nově se můžete objednat online",
    date: "19. 5. 2022",
    content:
      "<p>Objednávat online se můžete <a href='https://bojovnicezakrasnyusmev.xdent.cz/clinicSelection' target='_blank'>zde</a>.</p>",
  },
  {
    id: 4,
    title: "Další rozhovor pro web yarilo.cz",
    date: "11. 12. 2021",
    content:
      "<p>Přečtěte si další z mých rozhovorů. Budeme probírat péči o zuby v těhotenství. Odkaz naleznete <a href='https://yarilo.cz/kazi-se-behem-tehotenstvi-vice-zuby-jak-o-ne-pecovat-radi-dentalni-hygienistka/?utm_source=linktree&utm_campaign=IG_linktree&utm_medium=cpc&utm_content=clanek_dentalni_hygienicka' target='_blank'>zde</a>.</p>",
  },
  {
    id: 3,
    title: "Rozhovor se mnou pro web yarilo.cz",
    date: "11. 12. 2021",
    content:
      "<p>Můj rozhovor na téma 'Kdy začít s čištěním zubů u dětí?' si můžete přečíst <a href='https://yarilo.cz/na-ceste-za-krasnym-usmevem-kdy-zacit-s-cistenim-zubu-u-deti/?utm_source=linktree&utm_campaign=IG_linktree&utm_medium=cpc&utm_content=clanek_cisteni_zubu_detem' target='_blank'>zde</a>.</p>",
  },
  {
    id: 2,
    title: "Nově možná platba platební kartou",
    date: "6. 12. 2021",
    content:
      "<p>V ordinaci dentální hygieny je možná platba platební kartou.</p>",
  },
  {
    id: 1,
    title: "Od 1. 11. přijímám nové pacienty",
    date: "25. 11. 2021",
    content: "<p>Od 1. 11. přijímám nové pacienty na dentální hygienu.</p>",
  },
];
