import classes from "./Home.module.css";
import ReactHtmlParser from "react-html-parser";

import img from "../../assets/edited-mobile.jpg";

import { config } from "../../config/config";

const Home = () => {
  return (
    <div className="wrapper">
      <div className={classes.hero}>
        <div>
          <h1>Tereza Dobrová DiS.</h1>
          <h2>Dentální hygienistka</h2>
        </div>
      </div>
      <div className={classes.wrap}>
        <div className="section">
          <h3 className="heading">ZA ZUBY ZDRAVĚJŠÍ A ÚSMĚV KRÁSNĚJŠÍ</h3>

          <p>
            Oboru dentální hygiena se věnuji již řadu let. Praxi mám jako
            zdravotní sestra u zubního lékaře a později jako dentální
            hygienistka. Po mnohaletých zkušenostech jsem se rozhodla otevřít
            vlastní ordinaci dentální hygieny, která bude zaměřená jak na
            profesionální, tak i na přátelsky přístup. Ke každému pacientovi
            přistupuji individuálně a snažím se vždy jednoduše a srozumitelně
            vysvětlit postup pro domácí péči, který je schopen bez problémů
            zvládnout i doma.
          </p>
          <p>
            Pokud hledáte ordinaci s rodinou atmosférou a s pozitivním
            přístupem, jsem tu právě pro vás. Mým přáním je, aby každý odcházel
            z mé ordinace s dobrým pocitem a spokojený se svým novým úsměvem.
          </p>
          <img className="sectionImage" src={img} alt="Model zubů" />
        </div>

        <div className="section">
          <h3 className="heading">NOVINKY</h3>
          <div className="articles">
            {config.map((post, i) => {
              // eslint-disable-next-line array-callback-return
              if (i > 4) return;

              return (
                <article className="article" key={post.id}>
                  <h4 className="artHeading">{post.title}</h4>
                  <p className="date">{post.date}</p>
                  <p>{ReactHtmlParser(post.content)}</p>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
