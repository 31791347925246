import Gallery from "../UI/Gallery";
// import { useState } from "react";

import imgOrd1 from "../../assets/edited-8.jpg";
import imgOrd2 from "../../assets/edited-3.jpg";
import imgOrd3 from "../../assets/edited-16.jpg";
import imgOrd4 from "../../assets/edited-17.jpg";
import imgOrd5 from "../../assets/edited-28.jpg";
import imgOrd6 from "../../assets/edited-29.jpg";
import imgOrd7 from "../../assets/edited-4.jpg";
import imgOrd8 from "../../assets/ordinace.jpg";
import imgWait1 from "../../assets/edited-9.jpg";
import imgWait2 from "../../assets/edited-10.jpg";
import { useEffect } from "react";
import IgPostHomeWhitening from "../IG/IgPostHomeWhitening";
import IgPostDentalHygiene from "../IG/IgPostDentalHygiene";

const PhotoGallery = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "//www.instagram.com/embed.js";
    script.id = "igscript";
    document.querySelector("body").appendChild(script);
    return () => {
      document.querySelector("#igscript").remove();
    };
  });

  const waitingImages = [
    { src: imgWait1, alt: "Čekárna" },
    { src: imgWait2, alt: "Čekárna" },
  ];

  const ordinationImages = [
    {
      src: imgOrd8,
      alt: "Ordinace",
    },
    {
      src: imgOrd1,
      alt: "Ošetřování",
    },
    {
      src: imgOrd2,
      alt: "Ošetřování",
    },
    {
      src: imgOrd3,
      alt: "Dentální hygienistka s lupovkami",
    },
    {
      src: imgOrd4,
      alt: "Přístroj",
    },
    {
      src: imgOrd5,
      alt: "Zubní hygienistka",
    },
    {
      src: imgOrd6,
      alt: "Zubní hygienistka",
    },
    {
      src: imgOrd7,
      alt: "Zubní hygienistka",
    },
  ];

  return (
    <div className="wrapper">
      <div className="section section__big">
        <h3 className="heading">FOTOGALERIE</h3>
        <article className="article">
          <h4 className="artHeading">Čekárna</h4>
          <br />
          <br />
          <br />
          <Gallery imgs={waitingImages} />
        </article>
        <article className="article">
          <h4 className="artHeading">Ordinace</h4>
          <br />
          <br />
          <br />
          <Gallery imgs={ordinationImages} />
        </article>
        <article className="article">
          <h4 className="artHeading">Domácí bělení zubů</h4>
          <br />
          <br />
          <br />
          <IgPostHomeWhitening />
        </article>
        <article className="article">
          <h4 className="artHeading">Dentální hygiena</h4>
          <br />
          <br />
          <br />
          <IgPostDentalHygiene />
        </article>
      </div>
    </div>
  );
};

export default PhotoGallery;
