import img from "../../assets/edited-3-mobile.jpg";

const PriceList = () => {
  return (
    <div className="wrapper">
      <div className="section section__big">
        <h3 className="heading">NABÍZENÉ SLUŽBY</h3>
        <table>
          <tbody>
            <tr>
              <td>Vstupní dentální hygiena GBT (Guided Biofilm Therapy)</td>
              <td>1900 Kč</td>
            </tr>
            <tr>
              <td>Pravidelná dentální hygiena GBT (Guided Biofilm Therapy)</td>
              <td>1500 Kč</td>
            </tr>
            <tr>
              <td>Dětská dentální hygiena</td>
              <td>800 - 1200 Kč</td>
            </tr>
            <tr>
              <td>Air-Flow - odstranění pigmentací + fluoridace</td>
              <td>1200 Kč</td>
            </tr>
            <tr>
              <td>Domácí bělení (včetně bělících gelů + nosičů na bělení)</td>
              <td>5700 Kč</td>
            </tr>
            <tr>
              <td>Bělící gel</td>
              <td>450 - 900 Kč</td>
            </tr>
          </tbody>
        </table>
        <br />
        <img className="sectionImage" src={img} alt="Zubní protéza" />
      </div>
    </div>
  );
};

export default PriceList;
