import imgMobile from "../../assets/edited-13-mobile.jpg";

const WorkingHours = () => {
  return (
    <div className="wrapper">
      <div className="section section__big">
        <h3 className="heading">ORDINAČNÍ DOBA</h3>
        <table>
          <tbody>
            <tr>
              <td>Pondělí</td>
              <td>16:00 - 20:00</td>
            </tr>
            <tr>
              <td>Středa</td>
              <td>16:00 - 20:00 </td>
            </tr>
            <tr>
              <td>Čtvrtek</td>
              <td>16:00 - 20:00 </td>
            </tr>
            <tr>
              <td>Pátek</td>
              <td>8:00 - 15:00 </td>
            </tr>
          </tbody>
        </table>
        <img
          className="sectionImage"
          src={imgMobile}
          alt="Dentální hygienistka"
        />
      </div>
    </div>
  );
};

export default WorkingHours;
