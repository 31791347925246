import { Route } from "react-router-dom";
import Navigation from "./components/layout/Navigation";
import Contact from "./components/pages/Contact";
import GBT from "./components/pages/GBT";
import Home from "./components/pages/Home";
import News from "./components/pages/News";
import PhotoGallery from "./components/pages/PhotoGallery";
import PriceList from "./components/pages/PriceList";
import WorkingHours from "./components/pages/WorkingHours";

const App = () => {
  return (
    <div>
      <Navigation />
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/novinky">
        <News />
      </Route>
      <Route path="/osetreni-podle-gbt-protokolu">
        <GBT />
      </Route>
      <Route path="/fotogalerie">
        <PhotoGallery />
      </Route>
      <Route path="/cenik">
        <PriceList />
      </Route>
      <Route path="/ordinacni-doba">
        <WorkingHours />
      </Route>
      <Route path="/kontakt">
        <Contact />
      </Route>
    </div>
  );
};

export default App;
