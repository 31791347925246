import classes from "./LoadingSpinner.module.css";

const LoadingSpinner = ({ size, margin }) => {
  return (
    <div
      className={classes.loaderWrap}
      style={{ marginTop: margin, marginBottom: margin }}
    >
      <div
        className={classes["lds-facebook"]}
        style={{ width: size, height: size }}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
